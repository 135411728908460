import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
    providedIn: 'root'
})
export class AiKnowledgebaseService {


    constructor(
        private AppCMS: AppcmsService,
    ) {

    }

    getAll(options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'knowledgebase']);
    }

    save(entry: any) {
        return this.AppCMS.loadPluginData('pipeline', {
            entry: entry,
        }, ['ai', 'knowledgebase', 'save']);
    }

    update(entry: any) {
        return this.AppCMS.loadPluginData('pipeline', {
            entry: entry,
        }, ['ai', 'knowledgebase', 'update']);
    }

}